<template>
    <div>
        <div v-if="slotData && slotData.slot_type">
            <template v-for="(slotList, slotStatus) in slotData.slots">
                <div :key="slotStatus" v-if="slotData.slots">
                    <div class="mb-2">
                        <h6 class="mb-0" v-if="slotStatus == 'grade_up'">
                            {{ $t("grade_up_lessons") }}
                        </h6>
                        <h6 class="mb-0" v-if="slotStatus == 'repeat'">
                            {{ $t("lessons_you_have_taken") }}
                        </h6>
                        <h6 class="mb-0" v-if="slotStatus == 'not_taken'">
                            {{ $t("lessons_we_have_not_taken_before") }}
                        </h6>
                    </div>
                    <b-table
                        :empty-filtered-text="$t('no_result')"
                        :empty-text="$t('no_result')"
                        bordere
                        striped
                        responsive
                        :items="slotList"
                        :fields="getFields(slotData.filter, slotStatus)"
                        show-empty
                        class="mb-3 table-dropdown no-scrollbar border rounded"
                    >
                        <template #cell(add)="data">
                            <template v-if="data.item.course_id">
                                <div
                                    class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center"
                                    @click="data.toggleDetails"
                                >
                                    <i
                                        :class="
                                            data.detailsShowing
                                                ? 'ri-arrow-up-s-line font-size-20'
                                                : 'ri-arrow-down-s-line font-size-20'
                                        "
                                    ></i>
                                </div>
                            </template>
                            <template v-else-if="data.item.pool_id">
                                <b-button
                                    variant="primary"
                                    class="btn-icon"
                                    @click="
                                        getElectiveCourses(
                                            data.item.id,
                                            data.item.pool.name
                                        )
                                    "
                                >
                                    <i class="ri-add-line"></i>
                                </b-button>
                            </template>
                        </template>
                        <template #cell(course_code)="data">
                            <span v-if="data.item.course_id">
                                {{
                                    data.item.course && data.item.course.code
                                        ? data.item.course.code
                                        : "-"
                                }}
                            </span>
                            <span v-else> - </span>
                        </template>
                        <template #cell(course_name)="data">
                            <span v-if="data.item.course_id">
                                {{
                                    (data.item.course &&
                                        data.item.course.name) ||
                                    "-"
                                }}
                            </span>
                        </template>
                        <template #cell(pool_name)="data">
                            <span v-if="data.item.pool_id">
                                {{
                                    (data.item.pool && data.item.pool.name) ||
                                    "-"
                                }}
                            </span>
                        </template>
                        <template #cell(credit)="data">
                            <span v-if="data.item.course_id">
                                {{
                                    data.item.course && data.item.course.credit
                                        ? parseInt(data.item.course.credit)
                                        : "-"
                                }}
                            </span>
                            <span v-else>
                                {{
                                    data.item.credit
                                        ? parseInt(data.item.credit)
                                        : "-"
                                }}
                            </span>
                        </template>
                        <template
                            #cell(last_course)="data"
                            v-if="slotStatus != 'not_taken'"
                        >
                            <span v-if="data.item.last_course">
                                {{
                                    data.item.last_course.course_code
                                        ? data.item.last_course.course_code
                                        : ""
                                }}
                                -
                                {{ data.item.last_course.course_name || "-" }}
                            </span>
                        </template>
                        <template
                            #cell(last_course_letter_grade)="data"
                            v-if="slotStatus != 'not_taken'"
                        >
                            <span v-if="data.item.last_course">
                                {{
                                    data.item.last_course.letter_grade
                                        ? data.item.last_course.letter_grade
                                        : "-"
                                }}
                            </span>
                        </template>
                        <template #row-details="data">
                            <div class="row" v-if="data.item.course_id">
                                <div class="col-12">
                                    <b-row class="mb-2">
                                        <b-col v-if="data.item.sections" cols="12">
                                            <b-row >
                                                <b-table-simple>
                                                    <b-thead  v-b-hover  responsive>
                                                        <b-tr>
                                                            <b-th class="w-40"></b-th>
                                                            <b-th>{{$t('quota')}}</b-th>
                                                            <b-th>{{$t('remaining_quota')}}</b-th>
                                                            <b-th>{{$t('section')}}</b-th>
                                                            <b-th>{{$t('instructor_name')}}</b-th>
                                                            <b-th>{{$t('definition')}}</b-th>
                                                            <b-th>{{$t('schedule')}}</b-th>
                                                        </b-tr>
                                                    </b-thead>
                                                    <b-tbody>
                                                        <b-tr v-for="section in getSections(data.item)" :key="'section' + section.id">
                                                            <b-td>
                                                                <b-form-radio
                                                                v-model="slotSectionId[data.item.id]"
                                                                :name="'slotSection' + data.item.id"
                                                                :value="section.id"
                                                            />
                                                            </b-td>
                                                            <b-td>{{section.quota}}</b-td>
                                                            <b-td>{{section.remaining_quota}}</b-td>
                                                            <b-td>{{section.section}}</b-td>
                                                            <b-td>{{section.instructor_name}}</b-td>
                                                            <b-td v-if="section.definition">{{section.definition}}</b-td>
                                                            <b-td v-else>-</b-td>
                                                            <b-td v-if="section.course_schedules.length">
                                                                <div v-for="schedule in section.course_schedules" :key="'sectionSchedule' + schedule.id">
                                                                    {{ getDayText(schedule.day) }}: {{ schedule.start_time }} - {{ schedule.end_time }}
                                                                </div>
                                                            </b-td>
                                                            <b-td v-else>
                                                                -
                                                            </b-td>
                                                        </b-tr>
                                                    </b-tbody>

                                                </b-table-simple>

                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="col-12" v-if="allowEdit">
                                    <template v-if="data.item.course_id">
                                        <b-button
                                            variant="primary"
                                            @click="
                                                addCourse(
                                                    data.item.id,
                                                    data.item.course_id,
                                                    data.item.course.name,
                                                    getObjectValue(
                                                        data.item.course,
                                                        'code'
                                                    )
                                                )
                                            "
                                        >
                                            <i class="ri-add-line mr-3"></i>
                                            {{
                                                $t("take_the_lesson").toUpper()
                                            }}
                                        </b-button>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </b-table>
                </div>
            </template>
            <CommonModal id="electiveModal" ref="electiveModal" size="lg" :onHideOnlyX="true">
                <template v-slot:CommonModalTitle>
                    {{
                        electiveCoursePoolName
                            ? electiveCoursePoolName.toUpper()
                            : ""
                    }}
                </template>
                <template v-slot:CommonModalContent>
                    <div v-if="showElectiveModal && electiveCourseSlotId">
                        <b-table
                            :empty-filtered-text="$t('no_result')"
                            :empty-text="$t('no_result')"
                            bordered
                            responsive
                            :items="electiveCourses"
                            :fields="electiveCoursesFields"
                            show-empty
                            class="mb-4 table-dropdown no-scrollbar border rounded"
                            @row-clicked="onRowClicked"
                        >
                            <template #cell(add)="data">
                                <div
                                    class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center"
                                >
                                    <i
                                        :class="
                                            data.detailsShowing
                                                ? 'ri-arrow-up-s-line font-size-20'
                                                : 'ri-arrow-down-s-line font-size-20'
                                        "
                                    ></i>
                                </div>
                            </template>
                            <template #cell(course_name)="data">
                                {{ (data.item && data.item.course_name) || "" }}
                            </template>
                            <template #row-details="data">
                                <b-row
                                    v-if="
                                        Object.keys(data.item.sections).length >
                                        0
                                    "
                                >
                                    <b-col cols="12">
                                        <div class="text-muted mb-0">
                                            {{ $t("sections") }}
                                        </div>
                                        <b-row class="mb-1">
                                            <b-col cols="12">
                                                <b-table-simple
                                                    responsive
                                                    small
                                                    class="mb-3 border rounded"
                                                    v-if="data.item.sections.length"
                                                >
                                                    <thead>
                                                        <tr
                                                            style="
                                                                border-bottom: 1px
                                                                    solid
                                                                    #e9ecef;
                                                                background: #f1f1f1;
                                                            "
                                                        >
                                                            <th
                                                                colspan="6"
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "sections"
                                                                    ).toUpper()
                                                                }}
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th></th>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "section"
                                                                    ).toUpperCase()
                                                                }}
                                                            </th>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "instructor"
                                                                    ).toUpper()
                                                                }}
                                                            </th>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "quota"
                                                                    ).toUpper()
                                                                }}
                                                            </th>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "remaining_quota"
                                                                    ).toUpper()
                                                                }}
                                                            </th>
                                                            <th
                                                                class="text-center"
                                                            >
                                                                {{
                                                                    $t(
                                                                        "day"
                                                                    ).toUpper()
                                                                }},
                                                                {{
                                                                    $t(
                                                                        "hour"
                                                                    ).toUpper()
                                                                }}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <b-tbody>
                                                        <b-tr
                                                            v-for="section in getElectiveSections(data.item)"
                                                            :key="section.id"
                                                        >
                                                            <b-td
                                                                class="p-1 text-center"
                                                            >
                                                                <b-form-radio
                                                                    v-model="
                                                                        electiveSlotSectionId[electiveCourseSlotId+'_'+data.item.course_id]
                                                                    "
                                                                    :name="
                                                                        'course' +
                                                                        data
                                                                            .item
                                                                            .course_id +
                                                                        '-theoretical'
                                                                    "
                                                                    :value="
                                                                        section.id
                                                                    "
                                                                />
                                                            </b-td>
                                                            <b-td
                                                                class="p-1 text-center"
                                                                >Section
                                                                {{
                                                                    section.section
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        section.definition
                                                                    "
                                                                >
                                                                    -
                                                                    {{
                                                                        section.definition
                                                                    }}</span
                                                                ></b-td
                                                            >
                                                            <b-td
                                                                class="p-1 text-center"
                                                                >{{
                                                                    section.instructor_name
                                                                }}
                                                            </b-td>
                                                            <b-td
                                                                class="p-1 text-center"
                                                                >{{
                                                                    section.quota
                                                                }}</b-td
                                                            >
                                                            <b-td
                                                                class="p-1 text-center"
                                                                >{{
                                                                    section.remaining_quota
                                                                }}
                                                            </b-td>
                                                            <b-td class="p-1">
                                                                <template
                                                                    v-if="
                                                                        section
                                                                            .course_schedules
                                                                            .length
                                                                    "
                                                                >
                                                                    <div
                                                                        v-for="schedule in section.course_schedules"
                                                                        :key="
                                                                            'sectionSchedule' +
                                                                            schedule.id
                                                                        "
                                                                        class="fs-14"
                                                                    >
                                                                        <i
                                                                            class="ri-arrow-right-s-line mt-1"
                                                                        ></i>
                                                                        {{
                                                                            getDayText(
                                                                                schedule.day
                                                                            )
                                                                        }}
                                                                        {{
                                                                            schedule.start_time
                                                                        }}
                                                                        -
                                                                        {{
                                                                            schedule.end_time
                                                                        }}
                                                                    </div>
                                                                </template>
                                                            </b-td>
                                                        </b-tr>
                                                    </b-tbody>
                                                </b-table-simple>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="12" >
                                        <div class="d-flex">
                                            <b-button
                                                variant="primary"
                                                @click="
                                                    storeElectiveCourse(
                                                        electiveCourseSlotId,
                                                        data.item.course_id,
                                                        data.item.course_name
                                                    )
                                                "
                                            >
                                                <span
                                                    ><i
                                                        class="ri-add-line mr-3"
                                                    ></i>
                                                    {{
                                                        $t(
                                                            "take_the_lesson"
                                                        ).toUpper()
                                                    }}</span
                                                >
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <div v-else class="p-4">
                                    {{ $t("section_not_found") }}
                                </div>
                            </template>
                        </b-table>
                    </div>
                </template>
            </CommonModal>
        </div>
        <b-alert v-if="noData" variant="success" show>
            {{ $t("you_have_completed_all_courses_text") }}</b-alert
        >
    </div>
</template>

<script>
// Components
import CommonModal from "@/components/elements/CommonModal";

// Services
import CourseRegistration from "@/services/CourseRegistration";
import qs from "qs";

export default {
    name: "SlotPage",
    components: {
        CommonModal,
    },
    props: {
        slotData: {
            type: Object,
            default: null,
        },
        studentProgramId: {
            default: null,
        },
        allowEdit: {
            default: null,
        },
    },
    computed: {
        noData() {
            const keys = Object.keys(this.slotData);
            return keys.length < 3;
        },
    },
    data() {
        return {
            electiveCoursesFields: [
                { key: "add", label: " ", class: "w-40 p-1 align-middle" },
                {
                    key: "course_code",
                    label: this.$t("code").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                },
                {
                    key: "course_name",
                    label: this.$t("course").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                },
                {
                    key: "credit",
                    label: this.$t("credit").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                },
                {
                    key: "quota",
                    label: this.$t("quota"),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                },
            ],

            showElectiveModal: false,
            electiveCourses: [],
            electiveCoursePoolName: null,
            electiveCourseSlotId: null,

            electiveCourseDetailsRow: null,
            slotSectionId: [],
            electiveSlotSectionId: [],
        };
    },
    methods: {
        onRowClicked(item, index) {
            const { electiveCourseDetailsRow } = this;
            if (electiveCourseDetailsRow && electiveCourseDetailsRow !== item) {
                electiveCourseDetailsRow._showDetails = false;
            }

            this.$set(item, "_showDetails", !item._showDetails);
            this.electiveCourseDetailsRow = item._showDetails ? item : false;
        },
        getSections(item){
            if(item.sections.length==1){
                this.slotSectionId[item.id]=item.sections[0].id;
            }
            return item.sections;
        },
        getElectiveSections(item){
            if(!this.electiveCourseSlotId){
                return;
            }
            if(item.sections.length==1){
                this.electiveSlotSectionId[this.electiveCourseSlotId+'_'+item.course_id]=item.sections[0].id;
            }
            return item.sections;
        },
        getFields(filter, slotStatus) {
            let $return = [
                { key: "add", label: " ", class: "w-40 p-1 align-middle" },
            ];
            if (filter == "course") {
                $return.push({
                    key: "course_code",
                    label: this.$t("course_code").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                });
                $return.push({
                    key: "course_name",
                    label: this.$t("course_name").toUpper(),
                    sortable: true,
                    tdClass: "text-left align-middle",
                    thClass: "text-left",
                });
            } else {
                $return.push({
                    key: "pool_name",
                    label: this.$t("lesson_pool").toUpper(),
                    sortable: true,
                    tdClass: "text-left align-middle",
                    thClass: "text-left",
                });
            }

            $return.push({
                key: "credit",
                label: this.$t("credit").toUpper(),
                sortable: true,
                tdClass: "text-center align-middle d-none d-lg-table-cell",
                thClass: "text-center d-none d-lg-table-cell",
            });

            if (slotStatus != "not_taken") {
                $return.push({
                    key: "last_course",
                    label: this.$t("last_course").toUpper(),
                    sortable: true,
                    tdClass: "align-middle d-none d-lg-table-cell",
                    thClass: "d-none d-lg-table-cell",
                });
                $return.push({
                    key: "last_course_letter_grade",
                    label: this.$t("letter_grade").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle d-none d-lg-table-cell",
                    thClass: "text-center d-none d-lg-table-cell",
                });
            }
            return $return;
        },
        addCourse(slot_id, course_id, course_name, course_code) {
            if(!this.slotSectionId[slot_id]){
                this.$toast.error(
                    this.$t("no_course_selection_has_been_made")
                );
                return false;
            }
            this.$swal({
                title: this.$t("attention"),
                text: this.$t("are_you_sure_you_want_to_take_the_x_course", {
                    course_name: course_name,
                    course_code: course_code,
                }),
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = {
                        student_program_id: this.studentProgramId,
                        student_slot_id: slot_id,
                        course_id: course_id,
                        section_id: this.slotSectionId[slot_id]
                    };

                    CourseRegistration.store(formData)
                        .then((response) => {
                            if (typeof response.data.message != "undefined") {
                                this.$toast.success(
                                    this.$t("api." + response.data.message)
                                );
                            } else {
                                this.$toast.success(
                                    this.$t("successfully_added")
                                );
                            }
                            this.$emit("addedACourse", true);

                            if (
                                typeof response.data.data.info != "undefined" &&
                                response.data.data.info
                            ) {
                                this.$swal({
                                    text: this.getInfoMessage(
                                        response.data.data
                                    ),
                                    icon: "info",
                                    confirmButtonText: this.$t("ok"),
                                });
                            }
                        })
                        .catch((e) => {
                            this.showOverlapMessage(e);
                            this.showErrors(e);
                        });
                }
            });
        },

        showOverlapMessage(e) {
            try {
                if (
                    typeof e.data.message != "undefined" &&
                    ["LAB_SCHEDULE_OVERLAP", "SCHEDULE_OVERLAP"].includes(
                        e.data.message
                    )
                ) {
                    let msg = this.$t("schedule_overlap_message").replaceAll(
                        "#course",
                        e.data.errors[0].course.code +
                            " " +
                            e.data.errors[0].course.name
                    );
                    this.$swal({ text: msg, icon: "error" });
                }
            } catch (e) {}
        },

        getElectiveCourses(id, pool_name) {
            this.electiveCourseSlotId = id;
            this.electiveCoursePoolName = pool_name;

            const config = {
                params: {
                    limit: -1,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            CourseRegistration.getElectiveCourses(id, config)
                .then((response) => {
                    this.electiveCourses = response.data.data;
                    this.showElectiveModal = true;
                })
                .then((response) => {
                    this.$refs.electiveModal.$refs.commonModal.show();
                })
                .catch((e) => {
                    this.showErrors(e);
                });
        },
        storeElectiveCourse(slot_id, course_id, course_name) {
            if (
                !this.electiveSlotSectionId[this.electiveCourseSlotId+'_'+course_id]
            ) {
                this.$toast.error(
                    this.$t("no_course_selection_has_been_made")
                );
                return false;
            }

            let formData = {
                student_program_id: this.studentProgramId,
                student_slot_id: slot_id,
                course_id: course_id,
                section_id: this.electiveSlotSectionId[this.electiveCourseSlotId+'_'+course_id]
            };

            CourseRegistration.store(formData)
                .then((response) => {
                    if (typeof response.data.message != "undefined") {
                        this.$toast.success(
                            this.$t("api." + response.data.message)
                        );
                    } else {
                        this.$toast.success(this.$t("successfully_added"));
                    }
                    this.$bvModal.hide('electiveModal');
                    this.$emit("addedACourse", true);

                    if (
                        typeof response.data.data.info != "undefined" &&
                        response.data.data.info
                    ) {
                        this.$swal({
                            text: this.getInfoMessage(response.data.data),
                            icon: "info",
                            confirmButtonText: this.$t("ok"),
                        });
                    }
                })
                .catch((e) => {
                    this.showOverlapMessage(e);
                    this.showErrors(e);
                });
        },
    },
};
</script>
<style scoped>
.b-table-details td {
    background: #ffffff;
    padding: 20px;
}

.custom-control {
    padding-left: 3rem;
}
.table th, .table td {
    border:0 !important;
}
</style>
