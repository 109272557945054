<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_registration')" :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('course_registration')"
                    :isFilter="false"
                />
            </template>
            <div v-if="systemStatus != 'error' && loaded">

                <b-row>

                    <b-col cols="12" md="4" xl="3">
                        <h6 class="mb-2">
                            {{
                                student.student_number
                                    ? student.student_number
                                    : "-"
                            }}
                            - {{ student.name ? student.name : "" }}
                            {{ student.surname ? student.surname : "" }}
                            <br>{{ student.email }} <a v-if="student.mobile_tel">/ {{ student.mobile_tel }}</a>
                        </h6>
                        <div class="border rounded p-3 text-center">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group label="Program" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.program_name }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <b-form-group label="Legal Presence" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.legal_presence_type }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <b-form-group label="Registration Type" class="mb-2">
                                        <div class="label-as-input">
                                            {{ student.registration_type }}
                                        </div>
                                    </b-form-group>

                                </b-col>
                                <b-col cols="6">
                                    <b-form-group label="CGPA" class="mb-2">
                                        <div class="label-as-input">
                                            {{
                                                student.cgpa
                                                    ? student.cgpa
                                                    : "-"
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6">
                                    <b-form-group
                                        label="Registered Credits"
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.sum_of_credits
                                                    ? student.sum_of_credits
                                                    : 0
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                    <b-form-group
                                        :label="$t('advisor')"
                                        class="mb-2"
                                    >
                                        <div class="label-as-input">
                                            {{
                                                student.advisor
                                                    ? student.advisor
                                                    : "-"
                                            }}
                                        </div>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>

                                <b-col cols="12" class="mt-3">
                                    <div v-if="fee && fee.gross_price > 0">
                                        <table class="border-0 text-left w-100">
                                            <tr>
                                                <td>Tuition</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    ${{
                                                        fee.gross_price
                                                            | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-if="fee.total_discount">
                                                <td>Scholarship Applied</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    -${{
                                                        fee.total_discount
                                                            | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-for="discount in fee.discounts">
                                                <td>{{ discount.name }}</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    -${{
                                                        discount.discount
                                                            | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-if="fee.late_fee && fee.late_fee>0">
                                                <td>Late Registration Fee</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    ${{
                                                        fee.late_fee | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Remaining Tuition</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    ${{
                                                        fee.price | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-if="fee.paid_amount>0">
                                                <td>Paid Amount</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    -${{
                                                        fee.paid_amount | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-if="fee.refund_amount>0">
                                                <td>Refund Amount</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    ${{
                                                        fee.refund_amount | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                            <tr v-if="fee.cash_discount_amount>0">
                                                <td>Cash Discount</td>
                                                <td>:</td>
                                                <td class="pl-1 text-right">
                                                    -${{
                                                        fee.cash_discount_amount | formatNumber
                                                    }}
                                                </td>
                                            </tr>

                                            <tr v-if="fee.payment_plan">
                                                <td class="font-weight-bold">Balance</td>
                                                <td>:</td>
                                                <td :class="'pl-1 text-right font-weight-bold '+(fee.balance>0?'text-danger':'text-success')">
                                                    ${{
                                                        fee.balance | formatNumber
                                                    }}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div
                                        v-if="
                                            takenCourses && takenCourses.length
                                        "
                                        class="mt-3"
                                    >
                                        <div v-if="advisorApproval">
                                            <div v-if="isAdvisor || isAdmin">
                                                <div
                                                    :class="'alert alert-'+(advisorApproval.status=='approved'?'success':(advisorApproval.status=='declined'?'danger':'info'))">
                                                    Advisor Approval:
                                                    {{
                                                        advisorApproval.status_name
                                                    }}
                                                    <br v-if="advisorApproval.explanation">
                                                    {{
                                                        advisorApproval.explanation
                                                    }}
                                                </div>
                                                <div class="alert alert-warning"
                                                     v-if="advisorApproval.student_explanation">Students'
                                                    Note:<br>{{ advisorApproval.student_explanation }}
                                                </div>
                                                <div v-if="advisorApproval.status=='pending' && isActive">
                                                    <b-row>
                                                        <b-col cols="6">
                                                            <button
                                                                class="btn btn-success w-100"
                                                                @click="
                                                                    approve()
                                                                "
                                                            >
                                                                Approve
                                                            </button>
                                                        </b-col>
                                                        <b-col cols="6">
                                                            <button
                                                                class="btn btn-danger w-100"
                                                                @click="
                                                                    decline()
                                                                "
                                                            >
                                                                Decline
                                                            </button>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div v-else-if="isActive">
                                                    <button
                                                        class="btn btn-warning w-100"
                                                        @click="resetApproval()"
                                                    >
                                                        Reset Approval
                                                    </button>
                                                </div>
                                                <div v-if="isActive && isAdmin">
                                                    <button
                                                        v-if="advisorApproval.status =='declined'"
                                                        class="btn btn-primary w-100 mt-3"
                                                        @click="sendForAdvisorApproval()"
                                                    >
                                                        Send For Advisor Approval
                                                    </button>
                                                </div>
                                            </div>
                                            <div v-else-if="!isAdvisor">
                                                <div
                                                    :class="'alert alert-'+(advisorApproval.status=='approved'?'success':(advisorApproval.status=='declined'?'danger':'info'))">
                                                    Advisor Approval:
                                                    {{
                                                        advisorApproval.status_name
                                                    }}
                                                    <br v-if="advisorApproval.explanation">
                                                    {{
                                                        advisorApproval.explanation
                                                    }}
                                                </div>
                                                <div class="alert alert-warning"
                                                     v-if="advisorApproval.student_explanation">Students'
                                                    Note:<br>{{ advisorApproval.student_explanation }}
                                                </div>
                                                <div v-if="isActive">
                                                    <button
                                                        v-if="advisorApproval.status =='declined'"
                                                        class="btn btn-primary w-100"
                                                        @click="sendForAdvisorApproval()"
                                                    >
                                                        Send For Advisor Approval
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else-if="!isAdvisor && isActive">
                                            <button
                                                class="btn btn-primary w-100"
                                                @click="
                                                    sendForAdvisorApproval()
                                                "
                                            >
                                                Send For Advisor Approval
                                            </button>
                                        </div>

                                        <div v-if="advisorApproval && !isAdvisor && (isActive || (!isStudent && checkPermission('courseregistration_financialagreement')))">
                                            <button
                                                v-if="advisorApproval.status =='approved' && (!student.is_completed || (!isStudent && checkPermission('courseregistration_financialagreement')))"
                                                class="btn btn-success w-100 mt-3"
                                                @click="createPaymentPlan()"
                                            >
                                                PAYMENT OPTIONS
                                            </button>
                                            <button
                                                v-if="student.payment_plan && !student.is_completed"
                                                class="btn btn-danger w-100 mt-3"
                                                @click="onlinePayment"
                                            >
                                                MAKE PAYMENT
                                            </button>
                                        </div>

                                        <div
                                            v-if="advisorApproval && advisorApproval.status=='approved' && student.is_completed"
                                            class="alert alert-success mt-3">
                                            Course Registration Completed
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>

                    <b-col cols="12" md="8" xl="9" >
                        <div v-if="timerCount!=0 && message" class="d-flex align-items-center mt-1">
                            <div class="mr-1">
                                <b-button class="btn mb-4 width-90" :variant="timerButtonClass"
                                          :title="$t('session_timer_title')"
                                >
                                    <i class="ri-timer-line"></i>
                                    {{ timerCount }}
                                </b-button>
                            </div>
                            <div class="w-100">
                                <b-alert show variant="danger" class="text-center">{{ message }}</b-alert>

                            </div>
                        </div>

                        <h6 class="mb-2">
                            {{ semester.name }}
                        </h6>
                        <div class="border rounded-sm p-4">
                            <div class="tabs-no-tabcontent position-relative" v-if="tabs.length">
                                <TabButton
                                    v-model="selectedTab"
                                    :values="tabs"
                                />
                                <div class="tabs-bottom-line"></div>
                            </div>
                            <div class="pt-5 pb-0" v-if="tabs.length">
                                <div v-if="selectedTab == 'general'">
                                    <div class="mb-2">
                                        <div>
                                            <b-button
                                                :variant="
                                                    showType == 'list'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="mr-1 d-inline-block"
                                                @click="showType = 'list'"
                                            >
                                                <i
                                                    class="ri-file-list-2-line mr-1"
                                                ></i>
                                                {{ this.toUpperCase("list") }}
                                            </b-button>
                                            <b-button
                                                :variant="
                                                    showType == 'calendar'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="d-inline-block"
                                                @click="showType = 'calendar'"
                                            >
                                                <i
                                                    class="ri-calendar-todo-fill mr-1"
                                                ></i>
                                                {{
                                                    this.toUpperCase(
                                                        "course_schedule"
                                                    )
                                                }}
                                            </b-button>
                                            <b-button
                                                :variant="
                                                    showType == 'slot'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="d-inline-block ml-1"
                                                @click="goToSlot('list')"
                                            >
                                                {{
                                                    this.toUpperCase(
                                                        "slots"
                                                    )
                                                }}
                                            </b-button>
                                            <b-button
                                                :variant="
                                                    showType == 'transcript'
                                                        ? 'primary'
                                                        : 'outline-primary'
                                                "
                                                class="d-inline-block ml-1"
                                                @click="showTranscript(studentProgramId,'en', student.student_number)"
                                            >
                                                {{
                                                    this.toUpperCase(
                                                        "transcript"
                                                    )
                                                }}
                                            </b-button>
                                            <!--                                            <b-button-->
                                            <!--                                                :variant="-->
                                            <!--                                                    showType == 'addNote'-->
                                            <!--                                                        ? 'primary'-->
                                            <!--                                                        : 'outline-primary'-->
                                            <!--                                                "-->
                                            <!--                                                class="d-inline-block ml-1"-->
                                            <!--                                                @click="showType='addNote'"-->
                                            <!--                                            >-->
                                            <!--                                                {{-->
                                            <!--                                                    this.toUpperCase(-->
                                            <!--                                                        "Note"-->
                                            <!--                                                    )-->
                                            <!--                                                }}-->
                                            <!--                                            </b-button>-->
                                        </div>
                                    </div>
                                    <div v-if="showType == 'list'">
                                        <b-table
                                            :empty-filtered-text="
                                                $t('no_result')
                                            "
                                            :empty-text="$t('no_result')"
                                            bordered
                                            striped
                                            responsive
                                            :items="takenCourses"
                                            :fields="takenCourseFields"
                                            class="mb-0 table-dropdown no-scrollbar border rounded"
                                        >
                                            <template
                                                #cell(dropdownTable)="data"
                                            >
                                                <b-dropdown
                                                    v-if="allowEdit"
                                                    variant="link btn-sm"
                                                    boundary="window"
                                                    ref="dropdown"
                                                    no-caret
                                                >
                                                    <template #button-content>
                                                        <i
                                                            class="ri-more-fill"
                                                        ></i>
                                                    </template>
                                                    <div
                                                        id="dropdownList"
                                                        v-if="
                                                            !data.item.is_locked
                                                        "
                                                    >
                                                        <b-dropdown-item
                                                            v-if="
                                                                !data.item
                                                                    .is_locked &&
                                                                data.item
                                                                    .course_id &&
                                                                data.item
                                                                    .allow_section_change ==
                                                                    true &&
                                                                data.item
                                                                    .section_number
                                                            "
                                                            @click="
                                                                getSectionList(
                                                                    data.item
                                                                        .course_id,
                                                                    data.item
                                                                        .section_id,
                                                                    1
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i
                                                                    class="ri-pencil-fill align-middle top-minus-1 mr-3 text-muted"
                                                                ></i>
                                                                {{
                                                                    $t(
                                                                        "change_section"
                                                                    )
                                                                }}
                                                            </span>
                                                        </b-dropdown-item>
                                                        <b-dropdown-item
                                                            @click="
                                                                deleteCourse(
                                                                    data.item
                                                                        .student_slot_id,
                                                                    data.item
                                                                        .course_id
                                                                )
                                                            "
                                                        >
                                                            <span>
                                                                <i
                                                                    class="ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted"
                                                                ></i>
                                                                {{
                                                                    $t("delete")
                                                                }}
                                                            </span>
                                                        </b-dropdown-item>
                                                    </div>
                                                </b-dropdown>
                                            </template>
                                            <template #cell(slot_type)="data">
                                                {{ data.item.slot_type_text }}
                                            </template>
                                            <template #cell(credit)="data">
                                                {{
                                                    data.item.credit
                                                        ? parseInt(
                                                            data.item.credit
                                                        )
                                                        : "-"
                                                }}
                                            </template>
                                            <template #cell(course_name)="data">
                                                {{ data.item.course_name }}
                                            </template>
                                        </b-table>
                                    </div>
                                    <!--                                    <div v-if="showType == 'addNote'">-->
                                    <!--                                        <add-note :student="student"/>-->
                                    <!--                                    </div>-->
                                    <div v-if="showType == 'calendar'">
                                        <time-table
                                            :takenSectionIds="takenSectionIds"
                                        ></time-table>
                                    </div>
                                </div>
                                <div v-if="selectedTab == 'all_courses'">
                                    <b-table
                                        :empty-filtered-text="
                                                $t('no_result')
                                            "
                                        :empty-text="$t('no_result')"
                                        bordered
                                        striped
                                        responsive
                                        :items="allCourses"
                                        :fields="allCourseFields"
                                        class="mb-0 table-dropdown no-scrollbar border rounded"
                                    >
                                        <template #cell(course_code)="row">
                                            <div class="d-flex justify-content-between">
                                                <div
                                                    class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center"
                                                >
                                                    <i
                                                        @click="row.toggleDetails"
                                                        v-if="row.item.schedule.length"
                                                        :class="
                                                     row.detailsShowing
                                                ? 'ri-arrow-up-s-line font-size-20'
                                                : 'ri-arrow-down-s-line font-size-20'
                                        "
                                                    ></i>
                                                </div>
                                                <div class="mt-1">
                                                    {{ row.item.course_code }}
                                                </div>
                                            </div>


                                        </template>

                                        <template #cell(credit)="data">
                                            {{
                                                data.item.credit
                                                    ? parseInt(
                                                        data.item.credit
                                                    )
                                                    : "-"
                                            }}
                                        </template>
                                        <template #row-details="row">

                                            <b-card v-if="row.item.schedule.length">
                                                <table>
                                                    <tr>
                                                        <th>{{ $t('sections') }}</th>
                                                        <th>{{ $t('day') }}</th>
                                                        <th>{{ $t('start_time') }}</th>
                                                        <th>{{ $t('end_time') }}</th>
                                                    </tr>
                                                    <tr v-for="(data,i) in row.item.schedule" :key="i">
                                                        <td>{{
                                                                data.section
                                                            }}
                                                        </td>
                                                        <td>{{
                                                                data.day
                                                            }}
                                                        </td>
                                                        <td>{{
                                                                data.start_time
                                                            }}
                                                        </td>
                                                        <td>{{
                                                                data.end_time
                                                            }}
                                                        </td>
                                                    </tr>

                                                </table>
                                            </b-card>
                                            <b-card v-else>
                                                <div>data not found</div>
                                            </b-card>

                                        </template>
                                    </b-table>
                                </div>
                                <template v-for="slotData in slotTabs">
                                    <div
                                        :key="slotData.slot_type"
                                        v-if="selectedTab == slotData.slot_type"
                                    >
                                        <slot-page
                                            :slotData="
                                                getSlot(slotData.slot_type)
                                            "
                                            :allow-edit="allowEdit"
                                            :studentProgramId="studentProgramId"
                                            @addedACourse="addedACourse"
                                        />
                                    </div>
                                </template>
                                <div v-if="selectedTab == 'any_courses'">
                                    <b-table
                                        :empty-filtered-text="$t('no_result')"
                                        :empty-text="$t('no_result')"
                                        bordere
                                        striped
                                        responsive
                                        :items="anyCourses"
                                        :fields="getFields('course')"
                                        show-empty
                                        class="mb-3 table-dropdown no-scrollbar border rounded"
                                    >
                                        <template #cell(add)="data">
                                            <template v-if="data.item.course_id">
                                                <div
                                                    class="w-40 h-40 cursor-pointer d-flex justify-content-center align-items-center"
                                                    @click="data.toggleDetails"
                                                >
                                                    <i
                                                        :class="
                                            data.detailsShowing
                                                ? 'ri-arrow-up-s-line font-size-20'
                                                : 'ri-arrow-down-s-line font-size-20'
                                        "
                                                    ></i>
                                                </div>
                                            </template>
                                        </template>
                                        <template #row-details="data">
                                            <div class="row" v-if="data.item.course_id">
                                                <div class="col-12">
                                                    <b-row class="mb-2">
                                                        <b-col v-if="data.item.sections" cols="12">
                                                            <b-row >
                                                                <b-table-simple>
                                                                    <b-thead  v-b-hover  responsive>
                                                                        <b-tr>
                                                                            <b-th class="w-40"></b-th>
                                                                            <b-th>{{$t('quota')}}</b-th>
                                                                            <b-th>{{$t('remaining_quota')}}</b-th>
                                                                            <b-th>{{$t('section')}}</b-th>
                                                                            <b-th>{{$t('instructor_name')}}</b-th>
                                                                            <b-th>{{$t('definition')}}</b-th>
                                                                            <b-th>{{$t('schedule')}}</b-th>
                                                                        </b-tr>
                                                                    </b-thead>
                                                                    <b-tbody>
                                                                        <b-tr v-for="section in getSections(data.item)" :key="'section' + section.id">
                                                                            <b-td>
                                                                                <b-form-radio
                                                                                    v-model="courseSectionId[data.item.course_id]"
                                                                                    :name="'slotSection' + data.item.course_id"
                                                                                    :value="section.id"
                                                                                />
                                                                            </b-td>
                                                                            <b-td>{{section.quota}}</b-td>
                                                                            <b-td>{{section.remaining_quota}}</b-td>
                                                                            <b-td>{{section.section}}</b-td>
                                                                            <b-td>{{section.instructor_name}}</b-td>
                                                                            <b-td v-if="section.definition">{{section.definition}}</b-td>
                                                                            <b-td v-else>-</b-td>
                                                                            <b-td v-if="section.course_schedules.length">
                                                                                <div v-for="schedule in section.course_schedules" :key="'sectionSchedule' + schedule.id">
                                                                                    {{ getDayText(schedule.day) }}: {{ schedule.start_time }} - {{ schedule.end_time }}
                                                                                </div>
                                                                            </b-td>
                                                                            <b-td v-else>
                                                                                -
                                                                            </b-td>
                                                                        </b-tr>
                                                                    </b-tbody>

                                                                </b-table-simple>

                                                            </b-row>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="col-12" v-if="allowEdit">
                                                    <template v-if="data.item.course_id">
                                                        <b-button
                                                            variant="primary"
                                                            @click="
                                                addExchangeCourse(
                                                    data.item.course_id,
                                                    data.item.course_name,
                                                    data.item.course_code
                                                )
                                            "
                                                        >
                                                            <i class="ri-add-line mr-3"></i>
                                                            {{
                                                                $t("take_the_lesson").toUpper()
                                                            }}
                                                        </b-button>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <CommonModal
                    ref="changeSectionModal"
                    :onHideOnlyX="true"
                    size="lg"
                >
                    <template v-slot:CommonModalTitle>
                        {{ $t("change").toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <div v-if="changeSectionList">
                            <div class="d-flex">
                                <div class="flex-grow-1 mr-2">
                                    <b-form-select
                                        v-model="changeSectionSelect"
                                    >
                                        <option
                                            v-for="list in changeSectionList"
                                            :value="list.id"
                                            :key="list.id"
                                        >
                                            {{ list.section }}
                                            {{
                                                list.instructor
                                                    ? " - " +
                                                    list.instructor.name +
                                                    " " +
                                                    list.instructor.surname
                                                    : ""
                                            }}
                                            {{
                                                "- " +
                                                $t("remaining_quota") +
                                                ": " +
                                                list.remaining_quota
                                            }}
                                            {{
                                                (list.course_schedules || [])
                                                    .length
                                                    ? "- " +
                                                    $t("date") +
                                                    ": " +
                                                    getDayText(
                                                        list
                                                            .course_schedules[0]
                                                            .day
                                                    ) +
                                                    "- " +
                                                    $t("start_time") +
                                                    ":" +
                                                    getObjectValue(
                                                        list
                                                            .course_schedules[0],
                                                        "start_time"
                                                    )
                                                    : ""
                                            }}
                                        </option>
                                    </b-form-select>
                                </div>
                                <div>
                                    <b-button
                                        variant="primary"
                                        @click="
                                            changeSection(
                                                changeSectionCourseId,
                                                changeSectionSelect,
                                                changeSectionType
                                            )
                                        "
                                    >
                                        {{ $t("save") }}
                                    </b-button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            {{ $t("no_result") }}
                        </div>
                    </template>
                </CommonModal>
                <CommonModal
                    id="createPaymentPlan"
                    :onHideOnlyX="true"
                    size="lg"
                >
                    <template v-slot:CommonModalTitle>
                        {{ $t("payment_options").toUpper() }}
                    </template>
                    <template v-slot:CommonModalContent>
                        <b-row v-if="paymentStep==1">
                            <b-col cols="12">
                                <b-alert variant="default" class="border" show>
                                    <b-form-radio v-model="paymentOption" value="upfront_payment">UPFRONT PAYMENT (5%
                                        Discount)
                                    </b-form-radio>
                                </b-alert>
                            </b-col>
                            <b-col cols="12">
                                <b-alert variant="default" class="border" show>
                                    <b-form-radio v-model="paymentOption" value="payment_plan">PAYMENT PLAN
                                    </b-form-radio>
                                </b-alert>
                            </b-col>
                            <b-col cols="12">
                                <b-button @click="paymentStep=2" style="float: right" :disabled="paymentOption==null">
                                    Next
                                </b-button>
                            </b-col>
                        </b-row>

                        <b-row v-if="paymentStep==2">
                            <b-col cols="12" v-if="paymentOption=='upfront_payment'">
                                If you prefer to pay all your tuition balance upfront during the semester course
                                registration dates <b>({{ installmentDates[0].date }})</b>, Bay Atlantic University will
                                offer you a 5% discount on the tuition.
                                <br><br>
                            </b-col>
                            <b-col cols="12" v-if="paymentOption=='payment_plan'">
                                You have selected the option for a payment plan. The payment plan dates for
                                {{ semester.name }} are shown below. Paying all tuition and fees on time becomes an
                                obligation when you register at Bay Atlantic University. You must sign a Financial
                                Respoibility Agreement to proceed with payment plan BAU requests that you carefully
                                review and fully understand all disclosures before you sign your <b>Financial
                                Responsibility Agreement</b>.
                                <br><br>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Installment</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                    </tr>
                                    <tr v-for="(item, index) in installmentDates" :key="index">
                                        <td>Installment {{ index + 1 }}</td>
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.rate }}% of the semester balance</td>
                                    </tr>
                                </table>
                                <br>If you would like to continue, click on the <b>Next Button</b>.
                                <br><br>
                            </b-col>
                            <b-col cols="12" class="mt-2">
                                <b-button @click="paymentStep=1" style="float: left">Back</b-button>
                                <b-button @click="showFinancialAgreement" style="float: right">Next</b-button>
                            </b-col>
                        </b-row>
                    </template>
                </CommonModal>
                <CommonModal
                    id="financialAgreement"
                    :onHideOnlyX="true"
                    size="lg"
                >
                    <template v-slot:CommonModalTitle>
                        FINANCIAL RESPONSIBILITY AGREEMENT
                    </template>
                    <template v-slot:CommonModalContent>
                        <p>By proceeding with my registration, in exchange for being permitted to register for and
                            attend classes, I am entering into a Promissory Note (“Agreement”) (a financial obligation
                            in the form of an educational loan, as defined by the U.S. Bankruptcy Code at 11 U.S.C.
                            §523(a)(8)) in which Bay Atlantic University is providing me educational services and
                            deferring some or all of my payment obligation for those services, and I promise to pay for
                            all assessed tuition, fees and other associated costs by the published or assigned due date)
                            with Bay Atlantic University (known as "BAU" and "The University”) obligations me to pay all
                            outstanding monies owed to the University not paid by loans, scholarships, grants and/or
                            other payments.</p>
                        <p>I acknowledge I have read the Financial Responsibility Agreement and Academic Catalog pages
                            about payments, billing, and total withdrawals. I understand and agree that if I drop or
                            withdraw from some or all of the classes for which I register, I will be responsible for
                            paying all or a portion of tuition and fees in accordance with the published withdrawal
                            refund schedule posted at the Withdrawal/Leave of Absence page and/or any other policy
                            specific to my program or department, which I am responsible for reviewing and
                            understanding. I have read the terms and conditions of the published tuition refund schedule
                            and understand those terms are incorporated into this agreement by this reference. I further
                            understand that my failure to attend class or receive a bill does not release me from my
                            financial responsibility as described above.</p>
                        <p>This Agreement will cover my financial obligations to BAU for as long as I continue to incur
                            financial obligations to the University and/or have an outstanding balance on financial
                            obligations to BAU. BAU reserves the right to modify the terms and conditions of this
                            Agreement, prior to registration for any semester, by sending a written notice to me at my
                            last known billing address and my BAU email address and parent/proxy email address if
                            provided with FERPA Waiver Request Form. I am responsible for maintaining and supplying BAU
                            with my current billing address and phone number via my student portal (MyBAU).</p>
                        <p>Consistent with Bay Atlantic University’s delivery of services policy, in the event that BAU
                            determines it must suspend or alter its operations in whole or in part due to epidemic,
                            pandemic, other public health emergency, extreme weather, natural disaster, acts, or
                            threatened acts of terrorism or war, or any single act or combination of events beyond the
                            University’s control, Bay Atlantic University may suspend, reduce, terminate and/or modify
                            its operations in whole or in part, which may or may not include offering online or other
                            alternative learning options, in its discretion. In any such event, Bay Atlantic University
                            is under no obligation to refund or credit any portion of tuition, fees, or other charges
                            paid or owed, but it may do so in its discretion.</p>
                        <p>Should I fail to pay an outstanding balance in full when due, I agree that a $25 late fee per
                            credit is assessed for each late or insufficient payment. Accounts missing two or more
                            payments will be canceled from the plan and full payment becomes due immediately. I further
                            agree that should my account become delinquent, the University may withhold future
                            registration, awarding of any degree(s) or diplomas, and/or grades and official transcript
                            and not allow me to attend classes until the balance is paid.</p>
                        <p>Further, I understand that, if I fail to pay my student account bill or other monies owing to
                            Bay Atlantic University by the scheduled due date and fail to make acceptable payment
                            arrangements to bring my account current, the University may pursue litigation against me,
                            transfer my account to a collection agency and/or report any delinquency to credit
                            bureaus(s). In addition to my balance, I agree to pay the University’s cost and fees,
                            including attorneys’ fees and cost of collections agencies which will be calculated based on
                            the amount of the outstanding account balance, up to the maximum amount permitted by
                            applicable law. If a lawsuit is filed to recover an outstanding balance, I understand that I
                            will also be responsible for any costs associated with the lawsuit such as court costs.</p>
                        <p>I authorize the Bay Atlantic University and its agents, representatives, attorneys, and
                            contractors (including collection agencies) to contact me through my mobile phone, home
                            phone and email, including by way of text and automated message calls, for purposes of
                            collecting any portion of my student financial obligation which is past due.</p>
                        <p>I understand and agree that if I am younger than the applicable age of majority (generally,
                            18) when I execute this agreement, that the educational services provided by Bay Atlantic
                            University are a necessity or essential service, and I am therefore contractually obligated
                            by this agreement.</p>
                        <p>This agreement supersedes any previous understandings, representations or correspondence
                            between myself and Bay Atlantic University regarding the specific terms and conditions
                            contained in this agreement, and cannot be modified or affected by any course of dealing or
                            course of performance. This agreement may be modified by Bay Atlantic University if the
                            modification is signed by me (electronically or otherwise).</p>
                        <p>If any provision of this agreement, or any amount charged under this agreement, is determined
                            to be illegal or unenforceable, the remaining provisions of the agreement will remain valid
                            and enforceable to the extent permitted by law. This agreement and the performance of this
                            agreement are governed by the laws of the District of Columbia, without giving effect to its
                            principles of conflict of laws, and I agree that the state and federal courts located in the
                            District of Columbia will have jurisdiction to resolve any dispute arising out of this
                            agreement.</p>
                        <p>This agreement will remain in full force and effect for as long as I am enrolled in any class
                            or program at Bay Atlantic University, and thereafter for as long as I owe any amount of
                            money to the University.</p>
                        <p>I have read this Agreement and understand it. By selecting the “I Accept” button, I
                            understand and agree that I am signing this agreement electronically and my electronic
                            signature is the legal equivalent of my manual signature on this agreement under the
                            District of Columbia law. I understand and agree that the electronic signature appearing on
                            this agreement is the same as my handwritten signature for purposes of validity,
                            enforceability, and admissibility.</p>
                        <p class="font-weight-bold">
                            Student name: {{ student.name }}, {{ student.surname }}<br>
                            BAU ID: {{ student.student_number }}<br>
                            Email address: {{ student.email }}<br>
                            Date: {{ student.today }}
                        </p>
                        <div style="text-align: center">
                            <b-button variant="success" style="display: inline" @click="acceptAgreement">I Accept
                            </b-button>
                        </div>
                    </template>
                </CommonModal>
            </div>
            <div v-else-if="systemStatus == 'error'">
                <warning-page
                    :title="$t('information')"
                    :message="$t('api.' + systemStatusMessage)"
                />
                <div class="text-center"
                     v-if="systemStatusMessage && systemStatusMessage=='CANNOT_REGISTER_COURSES_WITHOUT_PAYMENT_APPROVAL'">
                    <button
                        class="btn btn-danger mt-3 d-inline-flex"
                        @click="onlinePayment"
                    >
                        MAKE PAYMENT
                    </button>
                </div>
            </div>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import TabButton from "@/components/elements/TabButton.vue";
import CommonModal from "@/components/elements/CommonModal";

// Page
import SlotPage from "./SlotPage";
import TimeTable from "./TimeTable";
import WarningPage from "@/components/page/WarningPage";

// Services
import CourseRegistration from "@/services/CourseRegistration";
import AdvisorApprovals from "@/services/AdvisorApprovals";
import qs from "qs";
import CustomerService from "@/services/CustomerService";
import Base from "@/plugins/Base";
import StudentDocumentService from "@/services/StudentDocumentService";
import i18n from "@/plugins/i18n";
import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        TabButton,
        SlotPage,
        TimeTable,
        CommonModal,
        WarningPage,
    },
    metaInfo() {
        return {
            title: this.$t("course_registration"),
        };
    },
    data() {
        return {
            // Student
            studentProgramId: null,
            student: [],
            loaded: false,

            // Tabs
            tabs: [],
            selectedTab: null,
            slotTabs: [],

            // Slots
            slots: [],
            allCourses: [],
            anyCourses: [],
            fee: null,
            allowEdit: null,
            advisorApproval: null,
            isAdvisor: null,
            isStudent: null,
            isAdmin: null,
            isActive: null,

            // System
            systemStatus: null,
            systemStatusMessage: null,

            // Show Type
            showType: "list",

            // Taken
            takenCourses: [],
            takenSectionIds: [],
            takenCourseFields: [
                {
                    key: "dropdownTable",
                    label: " ",
                    class: "p-0 text-center w-40 align-middle",
                },
                {
                    key: "course_code",
                    label: this.$t("course_code").toUpper(),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: "course_name",
                    label: this.$t("course").toUpper(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "section_number",
                    label: this.$t("section").toUpperCase(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "slot_type",
                    label: this.$t("slot_type").toUpper(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                },
                {
                    key: "credit",
                    label: this.$t("credit").toUpper(),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: "modality",
                    label: "MODALITY",
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    key: "repeated_course.course_name",
                    label: this.$t("last_course").toUpper(),
                    sortable: true,
                },
                {
                    key: "repeated_course.letter_grade",
                    label: this.$t("letter_grade").toUpper(),
                    sortable: true,
                },
            ],

            allCourseFields: [
                {
                    key: "course_code",
                    label: this.$t("course_code").toUpper(),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                    _showDetails: true
                },
                {
                    key: "course_name",
                    label: this.$t("course").toUpper(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                    _showDetails: true
                },
                {
                    key: "slot_type",
                    label: this.$t("slot_type").toUpper(),
                    sortable: true,
                    tdClass: "text-left",
                    thClass: "text-left",
                    _showDetails: true
                },
                {
                    key: "course_credit",
                    label: this.$t("credit").toUpper(),
                    sortable: true,
                    tdClass: "text-center",
                    thClass: "text-center",
                    _showDetails: true
                },
            ],
            creditLimit: 0,
            creditTotal: 0,
            id: null,
            timerCount: 0,

            changeSectionCourseId: null,
            changeSectionSelect: null,
            changeSectionType: null,
            changeSectionList: [],
            paymentOption: null,
            paymentStep: 1,
            installmentDates: null,
            semester: null,
            countDown: 0,
            timerButtonClass: null,
            timerDangerMin: 15 * 60,
            message: null,
            timerId:null,
            courseSectionId: [],
        };
    },
    computed: {
        userId() {
            let user = this.$store.getters["auth/getUser"];
            return user.id
        },


    },
    watch: {
        timerCount: {
            handler(value) {
                let splitData = value.split(':')
                let timeInSec = (parseInt(splitData[0]) * 60) + parseInt(splitData[1])
                if (timeInSec < this.timerDangerMin) {
                    this.timerButtonClass = 'outline-danger'
                } else {
                    this.timerButtonClass = 'outline-secondary'
                }
            }
        }
    },

    mounted() {

        this.studentProgramId = this.$route.params.id;

        if (this.studentProgramId !== undefined) {
            this.getSlots().then(() => {
                this.loaded = true;
            });
        } else {
            this.getFromUser().then(() => {
                this.getSlots().then(() => {
                    this.loaded = true;
                });
            });
        }
    },
    methods: {
        timer() {
            if(this.timerId){
                clearInterval(this.timerId);
            }
            if (this.student.timer) {
                this.message = this.student.timer_message
                const timePars = this.student.timer.split(':')
                let totalSeconds = (+timePars[0] * 3600 + (+timePars[1] * 60) + (+timePars[2]))
                this.timerId = setInterval(() => {
                    if (totalSeconds > 0) {
                        totalSeconds--
                        const hours = Math.floor(totalSeconds / 3600)
                        const minutes = Math.floor((totalSeconds % 3600) / 60)
                        const seconds = Math.floor(totalSeconds % 60)
                        this.timerCount = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    }
                }, 1000);
            } else {
                return
            }

        },
        async getFromUser() {
            let user = await this.$store.getters["auth/getUser"];
            if (
                user &&
                user.active_student_program &&
                user.active_student_program.id
            ) {
                this.studentProgramId = user.active_student_program.id;
            } else {
                this.$router.push("/404");
            }
        },

        async getSlots() {
            this.systemStatus = null;
            await CourseRegistration.getSlots(this.studentProgramId)
                .then((response) => {
                    this.systemStatus = "success";
                    this.student = response.data.data.student;
                    this.slots = response.data.data.slots;
                    this.allCourses = response.data.data.all_courses;
                    this.anyCourses = response.data.data.any_courses;
                    this.semester = response.data.data.semester;
                    this.installmentDates = response.data.data.installment_dates;
                    this.fee = response.data.data.fee;
                    this.advisorApproval = response.data.data.advisor_approval;
                    this.isAdvisor = response.data.data.is_advisor;
                    this.isStudent = response.data.data.is_student;
                    this.isAdmin = response.data.data.is_admin;
                    this.isActive = response.data.data.is_active || this.isAdmin;
                    this.allowEdit = response.data.data.allow_edit && this.isActive;
                    this.takenCourses = [];
                    this.takenCourses = response.data.data.taken_courses;
                    this.takenSectionIds = response.data.data.taken_section_id;
                    this.timer()

                })
                .then(() => {
                    this.tabs = [{
                        key: "general",
                        text: "Registered Courses",
                        icon: "ri-file-list-line",
                    }];

                    if (this.anyCourses.length) {
                        this.tabs.push({
                            key: "any_courses",
                            text: "All Courses",
                            icon: "ri-file-list-line",
                        });
                    }
                    else if (this.allCourses.length) {
                        this.tabs.push({
                            key: "all_courses",
                            text: "All Courses",
                            icon: "ri-file-list-line",
                        });
                    }

                    this.slotTabs = [];
                    for (let i in this.slots) {
                        let slotType = this.slots[i];
                        this.tabs.push({
                            key: slotType.slot_type,
                            text: slotType.slot_type_name,
                            icon: "ri-article-line"
                        });
                        this.slotTabs.push({
                            slot_type: slotType.slot_type,
                            filter: slotType.filter,
                            slot_type_name: slotType.slot_type_name,
                        });
                    }

                    if (!this.selectedTab) {
                        this.selectedTab = "general";
                    }
                })
                .catch((e) => {
                    this.systemStatus = "error";
                    this.systemStatusMessage = e.data.message;
                });
        },

        getFields(filter) {
            let $return = [
                { key: "add", label: " ", class: "w-40 p-1 align-middle" },
            ];
            if (filter == "course") {
                $return.push({
                    key: "course_code",
                    label: this.$t("course_code").toUpper(),
                    sortable: true,
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                });
                $return.push({
                    key: "course_name",
                    label: this.$t("course_name").toUpper(),
                    sortable: true,
                    tdClass: "text-left align-middle",
                    thClass: "text-left",
                });
            } else {
                $return.push({
                    key: "pool_name",
                    label: this.$t("lesson_pool").toUpper(),
                    sortable: true,
                    tdClass: "text-left align-middle",
                    thClass: "text-left",
                });
            }

            $return.push({
                key: "credit",
                label: this.$t("credit").toUpper(),
                sortable: true,
                tdClass: "text-center align-middle d-none d-lg-table-cell",
                thClass: "text-center d-none d-lg-table-cell",
            });

            return $return;
        },

        getSections(item){
            if(item.sections.length==1){
                this.courseSectionId[item.course_id]=item.sections[0].id;
            }
            return item.sections;
        },

        addExchangeCourse(course_id, course_name, course_code) {
            if (!this.courseSectionId[course_id]) {
                this.$toast.error(
                    this.$t("no_course_selection_has_been_made")
                );
                return false;
            }
            this.$swal({
                title: this.$t("attention"),
                text: this.$t("are_you_sure_you_want_to_take_the_x_course", {
                    course_name: course_name,
                    course_code: course_code,
                }),
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = {
                        student_program_id: this.studentProgramId,
                        course_id: course_id,
                        section_id: this.courseSectionId[course_id]
                    };

                    CourseRegistration.storeExchange(formData)
                        .then((response) => {
                            if (typeof response.data.message != "undefined") {
                                this.$toast.success(
                                    this.$t("api." + response.data.message)
                                );
                            } else {
                                this.$toast.success(
                                    this.$t("successfully_added")
                                );
                            }

                            this.addedACourse();

                            if (
                                typeof response.data.data.info != "undefined" &&
                                response.data.data.info
                            ) {
                                this.$swal({
                                    text: this.getInfoMessage(
                                        response.data.data
                                    ),
                                    icon: "info",
                                    confirmButtonText: this.$t("ok"),
                                });
                            }
                        })
                        .catch((e) => {
                            this.showOverlapMessage(e);
                            this.showErrors(e);
                        });
                }
            })
        },

        // Added
        addedACourse() {
            this.showType = "list";
            this.getSlots();
            this.selectedTab = "general";
        },


        // Section Change
        getSectionList(courseId, selected, type) {
            this.changeSectionCourseId = courseId;
            this.changeSectionSelect = selected;
            this.changeSectionType = type;
            const config = {
                params: {
                    type: type,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, {encode: false}),
            };
            CourseRegistration.getChangeSection(
                this.studentProgramId,
                courseId,
                config
            )
                .then((response) => {
                    this.changeSectionList = response.data.data;
                })
                .then((response) => {
                    this.$refs.changeSectionModal.$refs.commonModal.show();
                });
        },
        changeSection(courseId, sectionId, type) {
            CourseRegistration.putChangeSection(
                this.studentProgramId,
                courseId,
                sectionId,
                type
            )
                .then((response) => {
                    this.$toast.success(this.$t("successfully_changed"));
                    this.$refs.changeSectionModal.$refs.commonModal.hide();
                    this.getSlots();
                })
                .catch((e) => {
                    this.showErrors(e);
                });
        },

        // Delete
        deleteCourse(slotId, courseId) {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CourseRegistration.del(
                            this.studentProgramId,
                            slotId,
                            courseId
                        )
                            .then((response) => {
                                this.$toast.success(
                                    this.$t("successfully_deleted")
                                );
                                this.getSlots();
                            })
                            .catch((error) => {
                                this.$toast.error(
                                    this.$t("api." + error.data.message)
                                );
                            });
                    }
                });
        },
        createPaymentPlan() {
            this.$bvModal.show('createPaymentPlan');
        },

        sendForAdvisorApproval(withReason = false, withMsg = '') {
            let params = {
                text: "Are you sure you want to send for advisor approval?",
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
            };
            if (withReason) {
                let placeholder = 'Enter reason';
                if (withMsg) {
                    placeholder = withMsg;
                }
                params.input = 'textarea';
                params.inputPlaceholder = placeholder;
                params.inputValidator = (value) => {
                    if (!value) {
                        return "Enter reason";
                    }
                }
            }
            this.$swal
                .fire(params)
                .then((result) => {
                    if (result.isConfirmed) {
                        let data = {
                            student_program_id: this.studentProgramId,
                        };
                        if (withReason) {
                            data.student_explanation = result.value;
                        }
                        AdvisorApprovals.store(data)
                            .then((response) => {
                                this.advisorApproval = response.data.data;
                            })
                            .catch((error) => {
                                let msg = this.showErrors(error);
                                if (error.data.message.substring(0, 3) == 'MIN') {
                                    this.sendForAdvisorApproval(true, msg);
                                }
                            });
                    }
                });
        },
        approve() {
            this.$swal
                .fire({
                    text: "Are you sure you want to approve?",
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if(this.isAdmin){
                            AdvisorApprovals.updateStatusForAdmin(this.advisorApproval.id, {
                                status: "approved",
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                        else {
                            AdvisorApprovals.updateStatus(this.advisorApproval.id, {
                                status: "approved",
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                    }
                });
        },
        decline() {
            this.$swal
                .fire({
                    text: "Are you sure you want to decline?",
                    html: `<div>
                            <label id="charCount" style="display: block; margin-bottom: 5px;">
                              255 characters remaining
                            </label>

                            <textarea id="swal-input" class="swal2-textarea" placeholder="Enter explanation" style="width: 70%; height: 200px;"></textarea>
                          </div>`,
                    // input: "textarea",
                    // inputPlaceholder: "Enter explanation",
                    didOpen: () => {
                        const textarea = document.getElementById('swal-input');
                        const charCountLabel = document.getElementById('charCount');
                        const maxLength = 255;

                        textarea.addEventListener('input', function () {
                            const remaining = maxLength - textarea.value.length;
                            charCountLabel.textContent = `${remaining} characters remaining`;
                        });
                    },
                    preConfirm: () => {
                        return document.getElementById('swal-input').value;
                    },
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    inputValidator: (value) => {
                        if (!value) {
                            return "Enter explanation";
                        }
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if(this.isAdmin){
                            AdvisorApprovals.updateStatusForAdmin(this.advisorApproval.id, {
                                status: "declined",
                                explanation: result.value,
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                        else {
                            AdvisorApprovals.updateStatus(this.advisorApproval.id, {
                                status: "declined",
                                explanation: result.value,
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                    }
                });
        },
        resetApproval() {
            this.$swal
                .fire({
                    text: "Are you sure you want to reset approval?",
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if(this.isAdmin){
                            AdvisorApprovals.updateStatusForAdmin(this.advisorApproval.id, {
                                status: "pending",
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                        else {
                            AdvisorApprovals.updateStatus(this.advisorApproval.id, {
                                status: "pending",
                            })
                                .then((response) => {
                                    this.advisorApproval = response.data.data;
                                })
                                .catch((error) => {
                                    this.showErrors(error);
                                });
                        }
                    }
                });
        },
        getSlot(type) {
            return (this.slots || []).find((a) => a.slot_type === type) || {};
        },
        showFinancialAgreement() {
            this.$bvModal.hide('createPaymentPlan');
            this.$bvModal.show('financialAgreement');
        },
        acceptAgreement() {
            CourseRegistration.financialAgreement(this.student.id, {'payment_option': this.paymentOption})
                .then(response => {
                    this.$bvModal.hide('financialAgreement');
                    if (response.data.data.payment_plan) {
                        this.student.payment_plan = response.data.data.payment_plan
                    }
                }).catch(e => {
                this.showErrors(e);
            });
        },
        onlinePayment() {
            let data = {student_program_id: this.studentProgramId};
            CustomerService.createEpaymentLogin(data)
                .then(response => {
                    let data = response.data.data;
                    Base.LocalStorage.set('epayment_token', data.token);
                    this.$store.commit('epayment/setEpaymentToken', 'Bearer ' + data.token);
                    Base.LocalStorage.set('epayment_user', data.customer);
                    this.$store.commit('epayment/setEpaymentUser', data.customer)
                    this.$router.push('/epayment');
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        goToSlot(type) {
            this.showType = type
            window.open('/student/programs/' + this.$route.params.id + '/slots', '_blank')
        },
        showTranscript(userId, lang, studentNumber) {
            StudentDocumentService.downloadTranscript(userId, lang)
                .then(response => {
                    let fileName = studentNumber + '-' + i18n.t('file_name_transcript') + '-' + lang + '.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName: fileName});
                })
                .catch(e => {
                    showErrors(e, null, true)
                })
        }
    },
};
</script>

<style scoped>
button {
    line-height: 18px;
}
</style>
